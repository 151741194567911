:root {
  --redColor: #d01214;
  --lightGreyColor: #f2f2f2;
  --midGreyColor: #bebebe;
  --middarkGreyColor: #9e9e9e;
  --darkGreyColor: #747474;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --borderColor: #dedede;
  --yellow: #ffce2c;
}

body {
  font-family: "Roboto";
}

a{
  text-decoration: none!important;
}

button {
  transition: 0.3s;
}
button:hover {
  transition: 0.3s;
  opacity: 0.8;
}

input:focus {
  outline: none !important;
  outline-width: 0;
  box-shadow: none !important;
}
.form-control:focus, .form-select:focus{
  border-color: var(--redColor)!important;
}
select option:checked{
  background-color: var(--redColor)!important;
  color: var(--whiteColor);
}
.form-select option::selection {
  background-color: var(--redColor)!important;
  color: var(--whiteColor)!important;
}
.text-right {
  text-align: right;
}
.ml-3 {
  margin-left: 0.3em;
}
.ml-5 {
  margin-left: 0.5em;
}
.mr-5 {
  margin-right: 0.5em;
}
.mt-5 {
  margin-top: 0.5em;
}
.required {
  color: red;
}
.productBrands {
  height: 250px;
  overflow-y: auto;
}
.productBrands label {
  margin-left: 0.3em;
}
.productCategories {
  height: 250px;
  overflow-y: auto;
}
.productCategories label {
  margin-left: 0.3em;
}
.productCard {
  padding: 0 !important;
}
.productCard .nav-pills {
  border-right: 1px solid #ddd;
}
.productCard .nav-pills .nav-link {
  color: black;
  border-bottom: 1px solid #ddd;
  padding: 9px 11px;
  border-radius: 0;
}
.productCard .nav-pills .nav-link:hover {
  cursor: pointer;
}
.productCard .nav-pills .nav-link.active,
.productCard .nav-pills .show > .nav-link {
  background-color: #ddd !important;
  border-radius: 0;
  color: black;
  font-weight: 500;
}
.required1 {
  border-color: red;
  box-shadow: red !important;
  outline: red;
}
.required1:focus {
  border-color: red;
  box-shadow: 0px 0px 0px red !important;
  outline: red;
}
.text-right {
  text-align: right;
}
.title {
  /* text-align: center; */
  border: 0;
  /* height: 200px; */
}

.w-max {
  max-width: 100%;
}
.pagesMedia {
  max-width: 100%;
}
.pagesMediaButton {
  width: 100%;
}
.accordion {
  margin-top: 5px;
}
.modal-dialog {
  max-width: 1000px;
  margin: 1.75rem auto;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid var(--redColor);
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 3s linear infinite;
  text-align: center;
  margin-top: 30%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.center {
  position: relative;
  left: 92%;
  transform: translateX(-50%);
}

* {
  box-sizing: border-box;
}

.wrapper {
  height: 60px;
  width: 1000px;
  margin: 10px 0;
  position: relative;
  border-radius: 5px;
}
.wrapper::before {
  content: "";
  display: block;
  position: absolute;
  top: -7px;
  width: 100%;
  height: 4px;
  border-radius: 200px;
  background: black;
  opacity: 0;
  transition: opacity 0.05s;
}
.hover::before {
  opacity: 0.2;
}

.item {
  height: inherit;
  width: 100%;
  display: flex;
  /* background: #eee; */
  border-radius: 5px;
  overflow: hidden;

  opacity: 1;
  transition: opacity 0.1s;
}
.grabbing {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}

.handle {
  /* height: 70px;
  width: 1000px;
  padding: 20px;
  float: left; */
  cursor: grab;
}
.handle svg {
  height: 100%;
  width: 100%;
}

.content {
  padding: 30px;
  flex: 1 0;
}

.slideRight {
  margin-left: 20px;
}

button:hover {
  background-color: #ffffff;
  color: #666666;
}

button:disabled,
button[disabled] {
  background-color: #ffffff;
  color: #666666;
}
button[disabled]:hover {
  background-color: #ffffff;
  color: #666666;
}

.tab-content > .active {
  opacity: 1 !important;
}
.productImage {
  width: 100px;
  height: 100px;
}
.w-max {
  max-width: 1250px;
}

.modal-dialog {
  max-width: 1250px !important;
  margin: 1.75rem auto;
}
.settingsIcons {
  /* margin-left: 158px; */
  color: black;
  padding-top: 20px;
}
.a {
  text-decoration: none;
  color: black;
}
.a:hover {
  text-decoration: none;
  color: black;
}

.settingsCard {
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

aside {
  width: 270px;
  height: 100vh;
  /* background-color: #D01214; */
  /* background-color: var(--darkGreyColor); */
  background: rgb(116, 116, 116);
  background: linear-gradient(
    90deg,
    rgba(116, 116, 116, 1) 35%,
    rgba(89, 88, 88, 1) 100%
  );
  float: left;
  overflow-y: auto;
}

aside ul {
  padding: 0;
  list-style: none;
}

aside ul li {
  text-align: left;
  height: auto;
  margin-top: 5px;
}

/* aside ul li:first-child {
  height: 100px;
  padding-left: 25px;
  padding-bottom: 8px;
  position: fixed;
  width: 270px;
  margin-top: 0;
  padding-top: 0;
  top: 0;
} */

aside ul li:first-child a {
  padding: 0;
  background-color: transparent !important;
}

aside ul li:first-child a:hover {
  background-color: transparent;
}

/* aside ul li:nth-child(2) {
  padding-top: 120px;
} */

aside ul li a {
  text-decoration: none;
  color: var(--whiteColor);
  display: block;
  padding: 8px;
}

.asidelogo{
  /* width: 250px; */
  height: 100px;
}

aside button {
  color: var(--whiteColor);
  display: block;
  padding: 8px;
  background-color: transparent !important ;
  border: none;
  width: 100%;
  float: left;
}

aside button:hover {
  background-color: var(--whiteColor);
  color: white;
}
aside ul li a:hover {
  background-color: var(--redColor);
  color: white;
}

aside ul li a:hover .icon {
  color: white;
}

aside ul li a .logo {
  /* width: 145px;
  margin-top: 20px;
  margin-left: 20px; */
  margin-bottom: 30px;
}

aside ul li a .icon {
  width: 20px;
  color: var(--whiteColor);
  margin-left: 5px;
  font-size: 22px;
  height: 20px;
  display: inline-block;
  line-height: 19px;
  margin-right: 15px;
}
aside ul li .active {
  background-color: var(--redColor);
  color: white;
}
aside ul li .active .icon {
  color: white;
}

aside ul li span.title {
  color: var(--whiteColor);
  padding-left: 13px;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

aside::-webkit-scrollbar {
  width: 5px;
}

/* Track */
aside::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
aside::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
aside::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

.rightSide,
.topBar {
  width: calc(100% - 275px);
  float: left;
}

.rightSide {
  background-color: #fff;
  height: 100vh;
  padding-top: 45px;
  text-align: left;
  overflow-y: auto;
  padding-bottom: 200px;
}

.rightSide h5 {
  margin: 0;
  line-height: 38px;
}
aside::-webkit-scrollbar {
  width: 3px;
}
aside::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px var(--redColor);
}

.btn-primary {
  color: #fff !important;
  background-color: var(--redColor) !important;
  border-color: var(--redColor) !important;
  margin: 2px;
}
.btn-primary:focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn-primary:hover {
  background-color: var(--redColor) !important;
}

.configure {
  color: var(--redColor) !important;
}

.plusSideBar {
  background-color: transparent !important;
  margin-top: -10px;
  width: 45px;
  float: right !important;
  color: var(--whiteColor) !important;
}
/* .plusSideBar:hover {
  background-color: var(--redColor) !important ;
} */
.logoutSideBar {
  float: left !important;
  color: var(--whiteColor);
  background-color: transparent;
}
.mt-20 {
  margin-top: 20px;
}

.plusSideBar:focus {
  outline: none !important;
  box-shadow: none !important;
}
.logoutSpan {
  margin-left: -150px;
}
.btn-outline-primary {
  color: var(--redColor) !important;
  border-color: var(--redColor) !important;
}
.btn-outline-primary:hover {
  color: var(--whiteColor) !important;
  border-color: var(--redColor) !important;
  background-color: var(--redColor)!important;
}
.btn-outline-primary:focus {
  outline: none !important;
  box-shadow: none !important;
}
.form-control:focus {
  outline: var(--redColor) !important;
  box-shadow: var(--redColor) !important;
}
a {
  color: var(--redColor);
}
.bg-sis {
  --bs-bg-opacity: 1;
  background-color: var(--redColor) !important;
}
.text-primary {
  background-color: var(--redColor) !important;
}
.chip {
  padding: 4px 10px;
  background: var(--redColor) !important;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 11px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  color: #fff;
}

.btn-link {
  font-weight: 400;
  color: var(--redColor) !important;
  text-decoration: underline;
}
.btn-link:hover {
  font-weight: 400;
  color: var(--redColor) !important;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link:focus {
  outline: none !important;
  box-shadow: none !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white !important;
  background-color: var(--redColor) !important;
  cursor: pointer;
  text-align: center;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: var(--redColor) !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  text-align: center;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.flex-row {
  flex-direction: row !important;
}
.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #ecf0f3;
  border-radius: 0.42rem;
}

.nav-media-col {
  flex: 0 0 auto;
  width: 50%;
}
.icon {
  color: var(--whiteColor);
  text-decoration: none;
}
.icon:hover {
  color: var(--redColor);
}

.highlightOption {
  background: var(--redColor) !important;
  color: #fff;
}
.searchWrapper{
  border: 0;
}
.productCard .multiSelectContainer{
  position: relative!important;
}
.multiSelectContainer .option:hover{
  background: var(--redColor) !important;
  color: #fff;
}
.form-select:focus {
  outline: none !important;
  outline-width: 0;
  box-shadow: none !important;
}
.pagination-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10 !important;
}
page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
}
.page-link {
  position: relative;
  display: block;
  color: var(--redColor) !important;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.grey {
  color: rgb(150, 143, 143) !important;
  text-decoration: none !important;
}
.loginBox {
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  background-color: var(--lightGreyColor);
  opacity: 0.9;
  border-radius: 8px;
  padding: 2% 4%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 35%;
}
.LoginBackground {
  height: 100vh;
  /* background: rgb(255,173,221);
  background: linear-gradient(90deg, rgba(255,173,221,1) 0%, rgba(195,86,173,1) 100%); */
  background-image: url("./Images/background.png");
  opacity: 0.9;
  background-size: cover;
  background-repeat: no-repeat;
}
.loginRow {
  /* height: 100vh; */
}
.LoginBackground h1 {
  font-weight: bold;
}
.LoginBackground a {
  color: var(--midGreyColor) !important;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 30px;
  margin-left: 10px;
  width: 100%;
}
.LoginBackground button {
  margin-top: 30px;
  width: 100%;
}

.emounAya input {
  border: 0;
  height: 41px;
  border-radius: 21px;
  padding-left: 20px;
  font-size: 13px;
  font-family: "Lato";
  width: 100%;
  margin-bottom: 20px;
}

.emounAya input::placeholder {
  font-size: 13px;
  color: var(--middarkGreyColor);
}

.emounAya input:focus {
  border: 2px solid var(--redColor);
  outline: none;
}

.notification-icon {
  font-size: 15px;
  color: var(--whiteColor);
  cursor: pointer;
  text-decoration: none !important;
}

.notification-label {
  font-size: 17px !important;
  float:right;
}
.numbernotification {
  display: inline-block;
  padding-left: 55%;
}

.notification .active {
  background-color: var(--redColor);
  color: #fff;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.notification .nav-item {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 10px 10px 15px;
}
.order-card {
  color: #fff;
  cursor: pointer;
}
.order-card .dashboardicon {
  font-size: 26px;
  float: left;
}

.card .card-block {
  padding: 25px;
}

.bg-c-pink {
  background: rgb(208, 18, 20);
  background: linear-gradient(
    90deg,
    rgba(208, 18, 20, 1) 35%,
    rgba(244, 135, 137, 1) 100%
  );
}

.bg-c-lightpink {
  background: rgb(244, 135, 137);
  background: linear-gradient(
    90deg,
    rgba(244, 135, 137, 1) 35%,
    rgba(208, 18, 20, 1) 100%
  );
}

.crMKrN {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin: 10px;
}

.btJbkB.active {
  background: var(--redColor) !important;
  color: white !important;
}
/* .btn-danger {
  color: #fff;
  background-color: rgb(156, 46, 110) !important;
  border-color: rgb(156, 46, 110) !important;
} */
/* .jqx-cell  {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 16px;
  padding-right: 16px;
  word-break: break-word;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
  min-width: auto !important;
} */

/* .klgcSr {
  position: relative !important;
  display: flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center!important;
  -ms-flex-align: center !important;
  align-items: center !important;
  box-sizing: border-box !important;
  line-height: normal !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  word-break: break-word !important;
  -webkit-box-flex: 1 !important;
  -webkit-flex-grow: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  -webkit-flex-shrink: 0 !important;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
  -webkit-flex-basis: 0 !important;
  -ms-flex-preferred-size: 0 !important;
  flex-basis: 0 !important;
  max-width: 100% !important;
  min-width: 50px !important;
  width: 10px !important;
} */
.productList .rdt_TableHeadRow .rdt_TableCol:nth-child(1),
.productList .rdt_TableRow .rdt_TableCell:nth-child(1) {
  width: 15px !important;
  max-width: 15px !important;
}

.productList .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
.productList .rdt_TableRow .rdt_TableCell:nth-child(2) {
  width: 150px !important;
  max-width: 150px !important;
}

.productList .rdt_TableHeadRow .rdt_TableCol:nth-child(3),
.productList .rdt_TableRow .rdt_TableCell:nth-child(3) {
  width: 170px !important;
  max-width: 170px !important;
}
.productList .rdt_TableHeadRow .rdt_TableCol:nth-child(6),
.productList .rdt_TableRow .rdt_TableCell:nth-child(6) {
  width: 200px !important;
  max-width: 200px !important;
}
.productList .rdt_TableHeadRow .rdt_TableCol:nth-child(4),
.productList .rdt_TableRow .rdt_TableCell:nth-child(4) {
  width: 250px !important;
  max-width: 250px !important;
}
.productList .rdt_TableHeadRow .rdt_TableCol:nth-child(5),
.productList .rdt_TableRow .rdt_TableCell:nth-child(5) {
  width: 190px !important;
  max-width: 190px !important;
}
.productList .rdt_TableHeadRow .rdt_TableCol:nth-child(7),
.productList .rdt_TableRow .rdt_TableCell:nth-child(7) {
  width: 200px !important;
  max-width: 200px !important;
}

.portalTable .rdt_Table {
  border: 1px solid;
  border-radius: 8px 8px 0 0;
}
.portalTable .rdt_TableHead {
  color: var(--whiteColor);
}
.portalTable .rdt_TableHeadRow {
  background: rgb(116, 116, 116) !important;
  background: linear-gradient(
    90deg,
    rgba(116, 116, 116, 1) 35%,
    rgba(89, 88, 88, 1) 100%
  ) !important;
  border-radius: 8px 8px 0 0;
}
.portalTable .rdt_TableCol_Sortable {
  display: block;
  height: 20px;
}
.portalTable .rdt_Table span {
  position: absolute;
  right: 0;
  top: 15px;
}
.portalPath {
  padding: 0;
}

.portalPath li {
  display: inline;
  font-size: 13px;
  margin-right: 8px;
}

.portalPath li a {
  text-decoration: none;
  color: var(--blackColor);
}

.portalPath svg {
  font-size: 10px;
}

.tblSearchBar span {
  color: var(--darkGreyColor);
  font-size: 23px;
  left: 20px;
  line-height: 23px;
  position: absolute;
  top: 0;
}

.tblSearchBar .form-control {
  padding-left: 35px;
}

.accordion-button:not(.collapsed){
  color: #0d0d0d!important;
  background-color: transparent!important;
}
.accordion-button:focus{
  box-shadow: none!important;
}

.accordion-item{
  border: 0!important;
}

.accordion-header{
  border: 0!important;
  font-size: 16px;
}

.qNumber{
  margin-right: 30px;
  font-weight: bold;
}

.accordion-button:not(.collapsed)::after{
  /* width: 31px;
  height: 31px; */ 
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%2300000' class='bi bi-chevron-up' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/></svg>")!important;
  transform: none!important;
}

.accordion-button::after{
  /* width: 31px;
  height: 31px; */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%2300000' class='bi bi-chevron-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")!important;
}

.accordion-button:not(.collapsed){
  box-shadow: none!important;
}
#dropdown-date {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#dropdown-year {
  display: inline-block;
}

#dropdown-year select {
  background-color: #f5f5f5;
  box-shadow: none;
  border: 0;
  height: 45px;
}

#dropdown-month {
  display: inline-block;
  padding: 5px;
}

#dropdown-month select {
  background-color: #f5f5f5;
  box-shadow: none;
  border: 0;
  height: 45px;
}

#dropdown-day {
  display: inline-block;
  padding: 1px;
}

#dropdown-day select {
  background-color: #f5f5f5;
  box-shadow: none;
  border: 0;
  height: 45px;
}